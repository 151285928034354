<template>
  <div id="notification-modal-box" class="modal-card">
    <div class="card">
      <div class="card-content">
        <div class="level is-mobile">
          <div class="level-left">
            <h3>Notificações</h3>
          </div>
          <div class="level-right">
            <div class="modal-button-close" @click="$emit('close')">
              <b-icon pack="fas" type="is-magenta" icon="times"></b-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" :style="{ height: heightContent }">
        <div
          v-if="!isEmptyNotifications"
          class="columns is-gapless is-multiline is-mobile"
        >
          <div
            v-for="notification of notifications"
            :key="`notification-${notification.id}`"
            class="column is-full"
          >
            <div
              class="alert-item"
              :link="notification.link"
              :alt="notification.mensagem"
              @click="handleNotification(notification)"
            >
              <div class="columns is-mobile">
                <div class="column is-narrow">
                  <div class="centralize">
                    <div
                      :class="
                        `noti-tag-icon has-background-${
                          formatterIconMessage(notification.origem).type
                        }`
                      "
                    >
                      <b-icon
                        pack="fas"
                        :icon="formatterIconMessage(notification.origem).icon"
                        type="is-black"
                      ></b-icon>
                      <div
                        v-if="notification.link"
                        class="alert-item-forward has-background-purple"
                      >
                        <b-icon
                          pack="fas"
                          icon="share"
                          size="is-small"
                          type="is-white"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="noti-message">
                    <p>{{ notification.mensagem }}</p>
                    <div>
                      <span class="noti-message-span">{{
                        formatterDateFrom(notification.data_criacao)
                      }}</span>
                      <b-tag
                        v-if="!notification.visualizada"
                        :style="{ height: '1.5em', fontSize: '0.8em' }"
                        size="is-small"
                        rounded
                        type="is-alert"
                        >nova</b-tag
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="section centralize has-text-grey">
            <b-icon pack="fas" icon="inbox" size="is-large"></b-icon>
            <p>Sem notificações</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NotificationsMixin from "@/modules/requests/Notification";

export default {
  name: "ModalBox",
  mixins: [NotificationsMixin],
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      origem: {
        ligas: { icon: "trophy", type: "yellow" },
        infra: { icon: "cogs", type: "light" },
      },
    };
  },
  computed: {
    isEmptyNotifications() {
      return this.notifications.length === 0;
    },
    heightContent() {
      const screenHeight = window.screen.height - 74;
      return `${screenHeight}px`;
    },
  },
  methods: {
    handleNotification(notification) {
      if (notification.link) {
        this.setNotificationViewed(notification.id);
        window.open(notification.link, "_blank");
      }
    },
    formatterDateFrom(created) {
      return moment(created).from(moment());
    },
    formatterIconMessage(origem) {
      if (!this.origem[origem]) return { icon: "newspaper", type: "primary" };
      return this.origem[origem];
    },
    fromatterNumberNotifications(number) {
      if (number > 9) return number + "+";
      else return number;
    },
  },
};
</script>

<style lang="scss">
#notification-modal-box {
  .card {
    background-color: #292c3f;
    height: 100%;
    border-radius: 0px !important;
    display: table;

    .card-content:first-child {
      border-radius: 0px !important;
    }

    .card-body {
      width: 100%;
      padding: 0 1em;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .noti-tag-icon {
    border-radius: 50%;
    padding: 0.25em;
    position: relative;
  }

  .noti-message {
    padding: 0 1em;

    p {
      color: white;
      font-size: 1em;
      max-width: 200px;
    }
  }
  .noti-message-span {
    font-size: 0.8em;
    margin-right: 0.25em;
    color: rgba(255, 255, 255, 0.2);
  }

  .alert-item {
    padding: 0.5em;
    position: relative;
  }
  .alert-item[link]:hover {
    cursor: pointer;
    background-color: rgba(25, 118, 162, 0.25);
  }
  .alert-item:hover {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .alert-item:hover > .alert-item-options {
    display: flex;
  }
  .alert-item-options:hover {
    background-color: rgba(138, 138, 138, 0.2);
  }
  .alert-item-options {
    display: none;
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    right: 30px;
    top: 50%;
    transform: translate(0%, -50%);
  }
  .alert-item-forward {
    display: flex;
    cursor: pointer;
    position: absolute;
    max-height: 20px;
    min-width: 20px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* background-color: rgba(160, 18, 96, 0.2); */
    right: -5px;
    top: 80%;
    transform: translate(0%, -50%);
  }
}
</style>
