<template>
  <div :id="targetNotificationDOM">
    <div v-if="$mq === 'sm'" class="menu-group-item" @click="openModalBox">
      <b-icon pack="fas" icon="bell"></b-icon>
      Notificações
    </div>

    <b-dropdown
      v-if="$mq !== 'sm'"
      ref="dropdown-notification"
      position="is-bottom-left"
      @active-change="(ative) => troggleView(ative)"
    >
      <div slot="trigger" class="bt-icon">
        <div
          v-tooltip.bottom="{
            content: 'Notificações',
            classes: 'home-tooltip',
          }"
          :style="{ cursor: 'pointer' }"
        >
          <b-icon icon="bell" :pack="open ? 'far' : 'fas'"></b-icon>
        </div>
        <div v-if="lista_notificacoes.length > 0" class="noti-number">
          <b-tag type="is-magenta" rounded size="is-small">{{
            fromatterNumberNotifications(lista_notificacoes.length)
          }}</b-tag>
        </div>
        <div v-show="open && $mq != 'sm'" class="arrow-up-open">
          <b-icon pack="fas" icon="sort-up"></b-icon>
        </div>
      </div>

      <div class="notification-dropdown box-shadow">
        <div class="alert-item">
          <div class="level is-mobile">
            <div class="level-left">
              <h3 class="noti-title">Notificações</h3>
            </div>
            <div class="level-right">
              <div @click="troggleOptions()" class="noti-opcoes">
                <b-icon
                  pack="fas"
                  :class="{ 'rt-animation': openOptions }"
                  icon="ellipsis-h"
                  type="is-white"
                ></b-icon>
              </div>
            </div>
          </div>
        </div>

        <div v-show="openOptions" class="noti-opcoes-dropdown box-shadow">
          <div @click="readAll()" class="opcoes-drop-item">
            <div class="op-dp-it-icon">
              <b-icon
                type="is-white"
                pack="fas"
                icon="check"
                size="is-small"
              ></b-icon>
            </div>
            <div>Marcar todas como lidas</div>
          </div>
        </div>

        <div
          v-if="lista_notificacoes.length > 0"
          class="columns is-gapless is-multiline is-mobile"
        >
          <div
            v-for="notificacao of lista_notificacoes"
            :key="notificacao.id + '-notification'"
            class="column is-full"
          >
            <div
              class="alert-item"
              @click="clickItem(notificacao)"
              :link="notificacao.link"
              :alt="notificacao.mensagem"
            >
              <div class="level is-mobile">
                <div class="level-left">
                  <div
                    :class="
                      `noti-tag-icon has-background-${
                        formatterIconMessage(notificacao.origem).type
                      }`
                    "
                  >
                    <b-icon
                      pack="fas"
                      :icon="formatterIconMessage(notificacao.origem).icon"
                      type="is-black"
                    ></b-icon>
                    <div
                      v-if="notificacao.link"
                      class="alert-item-forward has-background-purple"
                    >
                      <b-icon
                        pack="fas"
                        icon="share"
                        size="is-small"
                        type="is-white"
                      ></b-icon>
                    </div>
                  </div>
                </div>
                <div class="level-item">
                  <div class="noti-message">
                    <p>{{ notificacao.mensagem }}</p>
                    <div>
                      <span class="noti-message-span">{{
                        formatterDateFrom(notificacao.data_criacao)
                      }}</span>
                      <b-tag
                        v-if="!notificacao.visualizada"
                        :style="{ height: '1.5em', fontSize: '0.8em' }"
                        size="is-small"
                        rounded
                        type="is-alert"
                        >nova</b-tag
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="section centralize has-text-grey">
            <b-icon pack="fas" icon="inbox" size="is-large"></b-icon>
            <p>Sem notificações</p>
          </div>
        </div>
      </div>
    </b-dropdown>

    <!-- Alertas nivel urgente -->
    <div class="noti-alert-level-2">
      <b-message
        :type="`is-${formatterIconMessage(notificacao.origem).type}`"
        has-icon
        :icon="formatterIconMessage(notificacao.origem).icon"
        icon-pack="fas"
        title="Notificação"
        v-for="notificacao of lista_urgente"
        :key="'m-not-' + notificacao.id"
        @close="clickAction(notificacao)"
      >
        <div>{{ notificacao.mensagem }}</div>
        <div v-if="notificacao.link" style="margin-top: 0.5em">
          <b-button @click="clickItem(notificacao)" type="is-pigeon">{{
            notificacao.link_label ? notificacao.link_label : "Saiba Mais"
          }}</b-button>
        </div>
      </b-message>
    </div>

    <ModalManager
      v-if="lista_modal.length > 0"
      :notificacao="lista_modal[indexModal]"
    ></ModalManager>
  </div>
</template>

<script>
import moment from "moment";
import NotificationsMixin from "@/modules/requests/Notification";

// imports componentes para modal
import ModalManager from "./ModalManager";
import ModalBoxMobile from "./ModalBox";
import { mapActions, mapGetters } from "vuex";

export default {
  type: "NotificationAlert",
  mixins: [NotificationsMixin],
  props: {
    tag: {
      type: String,
      default: "b-navbar-item",
    },
  },
  components: {
    ModalManager,
    ModalBoxMobile,
  },
  data() {
    return {
      modalBoxActive: false,
      open: false,
      openOptions: false,
      openDetails: false,
      openModal: false,
      indexModal: 0,
      targetNotificationDOM: "area-notification",
      origem: {
        ligas: { icon: "trophy", type: "yellow" },
        infra: { icon: "cogs", type: "light" },
      },
    };
  },
  async created() {
    moment.locale("pt-br");
    await this.getNotifications();

    if ("modal" in this.notificacoes && this.notificacoes["modal"].length > 0) {
      this.setNotificationActiveModal(true);
    } else this.setNotificationActiveModal(false);
  },
  computed: {
    ...mapGetters(["userInfo"]),
    lista_notificacoes() {
      if ("alerta" in this.notificacoes) {
        return this.notificacoes["alerta"].map((e) => {
          e.open = false;
          return e;
        });
      }
      return [];
    },
    lista_urgente() {
      if ("alerta" in this.notificacoes) {
        return this.notificacoes["alerta"].filter(
          (e) => e.nivel == "urgente" && !e.visualizada
        );
      }
      return [];
    },
    lista_modal() {
      if ("modal" in this.notificacoes) {
        return this.notificacoes["modal"].filter((e) => !e.visualizada);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["setNotificationActiveModal"]),
    troggleView(ative) {
      this.open = ative;
    },
    troggleOptions() {
      this.openOptions = !this.openOptions;
    },
    openModalBox() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalBoxMobile,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          notifications: this.lista_notificacoes,
        },
        events: {},
      });
    },
    clickItem(notificacao) {
      if (notificacao.link) {
        this.setNotificationViewed(notificacao.id);
        window.open(notificacao.link, "_blank");
      }
    },
    readAll() {
      for (let el of this.lista_notificacoes) {
        if (!el.visualizada) {
          this.setActions({
            id_notification: el.id,
            description: "Marcou todas como lidas",
            area: "view",
            status_action: "complete",
          });
        }
      }
      this.getNotifications();

      this.openOptions = false;
    },
    clickAction(notificacao) {
      this.setNotificationClosed(notificacao.id);
      this.getNotifications();
    },
    formatterDateFrom(created) {
      return moment(created).from(moment());
    },
    formatterIconMessage(origem) {
      if (!this.origem[origem]) return { icon: "newspaper", type: "primary" };
      return this.origem[origem];
    },
    fromatterNumberNotifications(number) {
      if (number > 9) return number + "+";
      else return number;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-group-item {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 0.5em;
    font-size: 9px;
  }
}
.noti-alert-level-2 {
  position: fixed;
  right: 2em;
  bottom: calc(2em + 80px);
  width: 400px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 22px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 22px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 22px -1px rgba(0, 0, 0, 0.5);
}
.noti-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 1em;
}
.noti-message p {
  color: white;
  font-size: 1em;
  max-width: 280px;
}
.noti-message-span {
  font-size: 0.8em;
  margin-right: 0.25em;
  color: rgba(255, 255, 255, 0.2);
}
.noti-new {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.noti-tag-icon {
  border-radius: 50%;
  padding: 0.25em;
  position: relative;
}
.rt-animation {
  transition: 0.2s ease-in-out;
  transform: rotate(180deg);
}
.alert-item {
  padding: 0.5em;
  position: relative;
}
.alert-item[link]:hover {
  cursor: pointer;
  background-color: rgba(25, 118, 162, 0.25);
}
.alert-item:hover {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}
.alert-item:hover > .alert-item-options {
  display: flex;
}
.alert-item-options:hover {
  background-color: rgba(138, 138, 138, 0.2);
}
.alert-item-options {
  display: none;
  cursor: pointer;
  position: absolute;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  right: 30px;
  top: 50%;
  transform: translate(0%, -50%);
}
.alert-item-forward {
  display: flex;
  cursor: pointer;
  position: absolute;
  max-height: 20px;
  min-width: 20px;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* background-color: rgba(160, 18, 96, 0.2); */
  right: -5px;
  top: 80%;
  transform: translate(0%, -50%);
}
.noti-title {
  font-size: 1.5em;
  color: white;
  font-weight: bolder;
  margin: 0.25em 0;
}
.noti-opcoes {
  position: relative;
  cursor: pointer;
}
.noti-detalhes-dropdown {
  position: absolute;
  padding: 0.5em;
  top: 50px;
  z-index: 100;
  width: 200px;
  max-width: 300px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background-color: #2f394b;
}
.noti-opcoes-dropdown {
  position: absolute;
  padding: 0.5em;
  top: 50px;
  z-index: 100;
  width: 338px;
  max-width: 340px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2f394b;
}
.opcoes-drop-item {
  padding: 0.5em;
  color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.opcoes-drop-item:hover {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.op-dp-it-icon {
  width: 25px;
  max-width: 25px;
  color: white;
}
.box-shadow {
  -webkit-box-shadow: -8px 10px 19px -9px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: -8px 10px 19px -9px rgba(0, 0, 0, 0.49);
  box-shadow: -8px 10px 19px -9px rgba(0, 0, 0, 0.49);
}

.arrow-up-open {
  position: absolute;
  top: 37px;
  color: #00fffd;
}
.noti-number {
  position: absolute;
  top: -3px;
  right: -15px;
}
</style>
