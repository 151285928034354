export function Menu(appName) {
  let menu = {
    cms: [
      {
        name: "PAINEL",
        url: "/painel",
        icon: "solar-panel",
        p: "painel",
      },
      {
        name: "DashBoard",
        url: "/cms/dashboard",
        icon: "chart-line",
        p: "cms.dashboard",
      },
      {
        name: "Usuários",
        url: "/cms/users",
        icon: "users-cog",
        p: "cms.users",
      },
      {
        name: "Acessos",
        url: "/cms/access",
        icon: "sign-in-alt",
        p: "cms.access",
      },
      {
        name: "Planos",
        url: "/cms/plans",
        icon: "cubes",
        p: "cms.plans",
      },
      {
        name: "Influenciadores",
        url: "/cms/influencers",
        icon: "user-astronaut",
        p: "cms.influencers",
      },
      {
        name: "Categorias",
        url: "/cms/categorias",
        icon: "tags",
        p: "cms.categorias",
      },
      {
        name: "Ligas",
        url: "/cms/ligas",
        icon: "trophy",
        p: "cms.ligas",
      },
      {
        name: "MiniGames",
        url: "/cms/minigames",
        icon: "gamepad",
        p: "cms.minigames",
      },
      {
        name: "Payout",
        url: "/cms/payout",
        icon: "money-bill-wave",
        p: "cms.payout",
      },
      {
        name: "Notificação",
        url: "/cms/notification",
        icon: "bell",
        p: "cms.notification",
      },
    ],
    ligas: [
      {
        name: "CMS",
        url: "/cms",
        icon: "user-cog",
        p: "cms",
      },
      // {
      //   name: "Desempenho",
      //   url: "/painel/desempenho",
      //   icon: "user-shield",
      //   p: "meusTimes",
      // },
      {
        name: "Atletas",
        url: "/painel/atletas",
        icon: "dumbbell",
        p: "atletas",
      },

      {
        name: "Cruzamento de Scouts",
        url: "/painel/cruzamento-scouts",
        icon: "chart-bar",
        p: "cruzamentoScouts",
      },
      // {
      //   name: "Jogos",
      //   url: "/painel/jogos",
      //   icon: "th-list",
      //   p: "jogos",
      // },
      // {
      //   name: "Gols e Assistências",
      //   url: "/painel/gols-assistencias",
      //   icon: "futbol",
      //   p: "assistenciasGols",
      // },
      // {
      //   name: "Ranking de Atletas",
      //   url: "/painel/ranking/atletas",
      //   icon: "sort-amount-up",
      //   p: "rankingAtletas",
      // },
      // {
      //   name: "Ranking de Clubes",
      //   url: "/painel/ranking/clubes",
      //   icon: "sort-amount-up",
      //   p: "rankingClubes",
      // },
      // {
      //   name: "Árbitros",
      //   url: "/painel/arbitros",
      //   icon: "sort-amount-up",
      //   p: "arbitros",
      // },
      // {
      //   name: "Clubes",
      //   url: "/painel/clubes",
      //   icon: "shield-alt",
      //   p: "clubes",
      // },
    ],
    painel_antigo: [
      {
        name: "CMS",
        url: "/cms",
        icon: "user-cog",
        p: "cms",
      },
      {
        name: "Ligas",
        url: "/ligas",
        icon: "trophy",
        p: "ligas",
      },
      {
        name: "Desempenho",
        url: "/painel/desempenho",
        icon: "user-shield",
        p: "meusTimes",
      },
      {
        name: "Atletas",
        url: "/painel/atletas",
        icon: "dumbbell",
        p: "atletas",
      },
      {
        name: "Jogos",
        url: "/painel/jogos",
        icon: "th-list",
        p: "jogos",
      },
      {
        name: "Estatísticas",
        icon: "stream",
        p: "",
        expanded: false,
        children: [
          {
            name: "Cruzamento de Scouts",
            url: "/painel/estatisticas",
            icon: "chart-bar",
            p: "cruzamentoScouts",
          },
          {
            name: "Gols e Assistências",
            url: "/painel/gols-assistencias",
            icon: "futbol",
            p: "assistenciasGols",
          },
          {
            name: "Ranking de Atletas",
            url: "/painel/ranking/atletas",
            icon: "sort-amount-up",
            p: "rankingAtletas",
          },
          {
            name: "Ranking de Clubes",
            url: "/painel/ranking/clubes",
            icon: "sort-amount-up",
            p: "rankingClubes",
          },
          {
            name: "Árbitros",
            url: "/painel/arbitros",
            icon: "sort-amount-up",
            p: "arbitros",
          },
        ],
      },
      {
        name: "Clubes",
        url: "/painel/clubes",
        icon: "shield-alt",
        p: "clubes",
      },
    ],
    painel: [
      {
        name: "CMS",
        url: "/cms",
        icon: "user-cog",
        p: "cms",
      },
      {
        name: "Atletas",
        url: "/painel/atletas",
        icon: "dumbbell",
        p: "atletas",
      },
      {
        name: "Cruzamento de Scouts",
        url: "/painel/cruzamento-scouts",
        icon: "chart-bar",
        p: "cruzamentoScouts",
      },
      {
        name: "Estatísticas",
        icon: "stream",
        p: "",
        expanded: false,
        children: [
          {
            name: "Resumo",
            url: "/painel/resumo",
            icon: "archway",
            p: "resumo",
          },
          {
            name: "Recorrência",
            url: "/painel/recorrencia",
            icon: "bullseye",
            p: "recorrencia",
          },
          {
            name: "Gols e Assistências",
            url: "/painel/gols-assistencias",
            icon: "futbol",
            p: "assistenciasGols",
          },
          {
            name: "Jogos",
            url: "/painel/jogos",
            icon: "th-list",
            p: "jogos",
          },
          // {
          //   name: "Desempenho",
          //   url: "/painel/desempenho",
          //   icon: "user-shield",
          //   p: "meusTimes",
          // },
          {
            name: "Árbitros",
            url: "/painel/arbitros",
            icon: "sort-amount-up",
            p: "arbitros",
          },
          {
            name: "Ranking de Atletas",
            url: "/painel/ranking-atletas",
            icon: "sort-amount-up",
            p: "rankingAtletas",
          },
          {
            name: "Ranking de Clubes",
            url: "/painel/ranking-clubes",
            icon: "sort-amount-up",
            p: "rankingClubes",
          },
          // {
          //   name: "Clubes",
          //   url: "/painel/clubes",
          //   icon: "shield-alt",
          //   p: "clubes",
          // },
        ],
      },
    ],
  };

  return menu[appName ? appName : "painel"].map((e) => {
    return { ...e, disabled: false };
  });
}
