<template>
  <div>
    <!-- Tipo Modal -->
    <b-modal
      v-if="!notificacao.personalizado"
      @close="clickAction(notificacao)"
      :active.sync="notificationActiveModal"
      :width="700"
    >
      <md-default :notificacao="notificacao"></md-default>
    </b-modal>

    <b-modal
      v-else
      @close="clickAction(notificacao)"
      :active.sync="notificationActiveModal"
      :width="700"
      :full-screen="$mq === 'sm'"
    >
      <component :is="notificacao.slug" :notificacao="notificacao"></component>
    </b-modal>
  </div>
</template>

<script>
import Default from "./ModalDefault";
import BlackFriday from "./custom/BlackFriday";
import ChamadaValorizacao from "./custom/ChamadaValorizacao";
import ChamadaPesquisa from "./custom/ChamadaPesquisa";
import ChamadaRenovacao from "./custom/ChamadaRenovacao";

import NotificationsMixin from "@/modules/requests/Notification";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ModalManager",
  mixins: [NotificationsMixin],
  props: {
    notificacao: {
      type: Object,
      required: true,
    },
  },
  components: {
    "md-default": Default,
    BlackFriday: BlackFriday,
    ChamadaValorizacao,
    ChamadaPesquisa,
    ChamadaRenovacao,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["notificationActiveModal"]),
  },
  methods: {
    ...mapActions(["setNotificationActiveModal"]),
    clickAction(notificacao) {
      this.setActions({
        id_notification: notificacao.id,
        description: "Fechou a notificação pela parte externa",
        area: "ignored",
        status_action: "complete",
      });
      this.setNotificationActiveModal(false);
    },
  },
};
</script>

<style scoped></style>
