<template>
  <div class="not-modal-container has-background-darkblue">
    <div class="not-modal-close" @click="clickAction(notificacao)">
      <b-icon icon="times-circle" pack="fas"></b-icon>
    </div>

    <div class="not-modal-header">
      <h2 class="not-modal-title">Liga Tiro Curto Valorização</h2>
      <span class="not-modal-subtitle">{{
        formatterDateFrom(notificacao.data_criacao)
      }}</span>
    </div>

    <div class="not-modal-body">
      <div class="columns is-gapless">
        <div class="column">
          <div class="not-modal-content">
            <p class="not-modal-article">{{ notificacao.mensagem }}</p>
            <div v-if="notificacao.link" :style="{ marginTop: '1em' }">
              <b-button
                @click="clickItem(notificacao)"
                type="is-primary"
                outlined
                >{{
                  notificacao.link_label ? notificacao.link_label : "Saiba Mais"
                }}</b-button
              >
            </div>
          </div>
        </div>
        <div class="column">
          <img src="@/assets/ilustracoes/mulher-ranking.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NotificationsMixin from "@/modules/requests/Notification";
import { mapActions } from "vuex";

export default {
  name: "ChamadaValorizacao",
  mixins: [NotificationsMixin],
  props: {
    notificacao: {
      type: Object,
      required: true,
    },
  },
  async created() {
    moment.locale("pt-br");
  },
  methods: {
    ...mapActions(["setNotificationActiveModal"]),
    formatterDateFrom(created) {
      return moment(created).from(moment());
    },
    clickItem(notificacao) {
      if (notificacao.link) {
        this.setNotificationViewed(notificacao.id);
        notificacao.link = notificacao.link.replace(":uid", this.userId);
        window.open(notificacao.link, "_blank");
        this.setNotificationActiveModal(false);
      }
    },
    clickAction(notificacao, view) {
      this.setNotificationModalClosed(notificacao.id);
      this.getNotifications();
      this.setNotificationActiveModal(false);
    },
  },
};
</script>

<style scoped>
.not-modal-container {
  width: 100%;
  min-height: 350px;
  padding: 1.5em;
  position: relative;
  border-radius: 4px;
}
.not-modal-close {
  position: absolute;
  right: 2em;
  top: 2em;
}
.not-modal-close:hover {
  color: rgb(235, 57, 119);
}
.not-modal-header {
  width: 100%;
  padding: 0.5em 0;
}
.not-modal-body {
  width: 100%;
}
.not-modal-content {
  margin-top: 1em;
  padding: 0.25em 0;
  display: flex;
  flex-direction: column;
}
.not-modal-article {
  font-size: 1em;
  color: white;
  text-align: justify;
}

.not-modal-header .not-modal-title {
  font-size: 1.5em;
  font-weight: bolder;
}
.not-modal-header .not-modal-subtitle {
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.3);
}
</style>
